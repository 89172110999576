.Advisory  {
        position: fixed;
    left: 0;
    top: 0;
    z-index: 999999;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
        transition: all 0.5s ease-in;
}


.advisory-closer {
    border: none;
    background-color: var(--primary-blue);
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 10;
}

.advisory-closer:hover {
    background-color: var(--base-3);
}

.advisory-container {
    width: 600px;
    height: 400px;
    background-color: white;
    border-radius: 20px;
    position: relative;
    transition: all 0.5s ease-in;
    transform: translateY(0%) scale(1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    text-align: center;
}

.advisory-closed {
    transform: translateY(200%) scale(0.2);
    box-shadow: 0 0 4px #8a31ffcc;
}

@media (max-width:460px) {
    .advisory-container {
        width: 300px;
    }
}