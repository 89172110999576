/** @format */

.Inscriptions {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 999999;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.6);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transition: all 0.5s ease-in;
	padding: 5% 0;
	opacity: 1;
	visibility: visible;
}

.Inscriptions.close {
	opacity: 0;
	visibility: hidden;
	transition: all 0.5s ease-in;
}

.Inscriptions-close-btn {
	position: absolute;
	border: none;
	top: -10px;
	right: -10px;
	z-index: 10;
	background-color: var(--primary-blue);
	color: white;
	width: 30px;
	height: 30px;
	border-radius: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	cursor: pointer;
}

.btn-camb {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	bottom: -20px;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	background: linear-gradient(90deg, var(--primary-blue) 50%, var(--cyan) 90%);
	color: white;
	width: 200px;
	height: 50px;
	border-radius: 5px;
	cursor: pointer;
	text-decoration: none;
}

.btn-camb:hover {
	background: var(--primary-blue);
}

@media screen and (max-width: 720px) {
	.btn-camb {
		width: 100px;
		height: 40px;
		font-size: 14px;
	}
}

.Inscriptions-content {
	height: 80vh;
	aspect-ratio: 1;
	position: relative;
	transform: scale(1);
	transition: all 0.3s ease-in;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-ms-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
}

.Inscriptions-content.close {
	transform: scale(0);
	transition: all 0.3s ease-in;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-ms-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
}

.Inscriptions-content img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	border-radius: 20px;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	-ms-border-radius: 20px;
	-o-border-radius: 20px;
}

@media screen and (max-width: 860px) {
	.Inscriptions-content {
		height: auto;
		width: 90vw;
	}
}
