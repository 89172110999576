.sectionMain {
	padding: 30px 0;
	background-color: #fafafa;
}

.titleContainer {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-bottom: 24px;
	padding: 0 24px;
}

.titleContainer h1 {
	width: 100%;
	text-align: center;
	font-size: 40px;
	font-weight: 500;
}

.titleContainer h1 span {
	color: #b366cf;
	background-image: linear-gradient(180deg, #ffffff 75%, #4353ff 0%);
	border-radius: 0px 0px 0px 0px;
}

.shin {
	max-height: 50px;
}

.thunder {
	max-width: 40px;
}

.imageMainContainer {
	display: flex;
	gap: 18px;
	align-items: center;
	justify-content: center;
	padding: 0 18px;
}

.imageContainer {
	position: relative;
	max-width: 300px;
	max-height: 300px;
	overflow: hidden;
	border-radius: 15px;
}

.imageContainer:hover .textContainer {
	bottom: -300px;
}

.imageContainer img {
	width: 100%;
	height: 100%;
	border-radius: 15px;
}

.imageContainerCenter {
	position: relative;
	max-width: 400px;
	max-height: 670px;
	overflow: hidden;
	border-radius: 15px;
}

.imageContainerCenter img {
	/* object-fit: cover; */
	width: 100%;
	height: 100%;
	border-radius: 15px;
}

.imageContainerCenter:hover .textContainer {
	bottom: -670px;
}

.textContainer {
	position: absolute;
	bottom: 0;
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.95) 100%);
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 24px;
	color: rgb(230, 230, 230);
	border-radius: 15px;
	transition: 0.3s ease-in all;
}

.textContainer h1 {
	font-size: 20px;
	font-weight: 500;
}

.textContainer h2 {
	font-size: 14px;
	font-weight: 400;
}

.playButton {
	position: absolute;
	margin: auto;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--primary-blue);
	height: 0px;
	width: 0px;
	font-size: 0px;
	color: rgb(230, 230, 230);
	border-radius: 30px;
	padding-left: 2px;
	cursor: pointer;
	z-index: 1;
}

.imageContainer:hover .playButton {
	height: 50px;
	width: 50px;
	font-size: 18px;
	transition: 0.2s ease-in-out all;
	transition-delay: 0.1s;
}

.imageContainerCenter:hover .playButton {
	height: 50px;
	width: 50px;
	font-size: 18px;
	transition: 0.2s ease-in-out all;
	transition-delay: 0.1s;
}

.images2 {
	display: flex;
	flex-direction: column;
	gap: 18px;
}

.imageMainContainerMobile {
	display: none;
}

@media screen and (max-width: 1100px) {
	.textContainer {
		padding: 24px 12px;
	}

	.textContainer h1 {
		font-size: 16px;
	}

	.textContainer h2 {
		font-size: 12px;
	}
}

@media screen and (max-width: 1000px) {
	.textContainer {
		padding: 24px;
	}

	.textContainer h1 {
		font-size: 20px;
	}

	.textContainer h2 {
		font-size: 14px;
	}

	.imageMainContainer {
		display: none;
	}

	.imageMainContainerMobile {
		display: flex;
		flex-direction: column;
		gap: 18px;
		align-items: center;
		justify-content: center;
		padding: 0 18px;
	}

	.layoutImageContainerMobile {
		display: flex;
		gap: 18px;
	}

	.imageContainer {
		max-width: 400px;
		max-height: 400px;
	}

	.imageContainerCenter {
		position: relative;
		max-width: 400px;
		max-height: 818px;
	}

	.imageContainerCenter img {
		width: auto;
		height: 100%;
		object-position: -40px center;
	}
}

@media screen and (max-width: 850px) {
	.imageMainContainerMobile {
		gap: 12px;
	}

	.layoutImageContainerMobile {
		gap: 12px;
	}

	.imageContainer {
		max-width: 400px;
		max-height: 400px;
	}

	.imageContainerCenter {
		position: relative;
		max-width: 400px;
		max-height: 818px;
		width: 50%;
	}

	.imageContainerCenter img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.images2 {
		width: 50%;
	}
}

@media screen and (max-width: 450px) {
	.layoutImageContainerMobile {
		flex-direction: column;
	}

	.images2 {
		width: 100%;
	}

	.imageContainerCenter {
		width: 100%;
	}

	.imageContainerCenter img {
		object-position: 0px center;
	}

	.titleContainer {
		padding: 0 24px;
	}

	.titleContainer h1 {
		font-size: 30px;
	}

	.shin {
		max-height: 40px;
	}

	.thunder {
		max-width: 30px;
	}
}
