
.App {
    width: 100%;
  overflow: hidden;
  background-color:  #000000;
}


.spinner {
  border: 6px solid #6000de;
  border-top: 6px solid #6000de88;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  

  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
