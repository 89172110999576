.WhatsappMobile {
	display: none;
}

@media screen and (max-width: 460px) {
	.WhatsappMobile {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		position: fixed;
		bottom: 20px;
		left: -50px;
		transform: translate(50%);
		background-color: #00bb2d;
		padding: 8px 12px;
		z-index: 1000;
		border-radius: 16px;
		color: #ffffff;
		max-width: 95%;
		width: 130px;
		height: 55px;
		font-size: 13px;
		text-decoration: none;
		box-shadow: 0 10px 10px 4px rgba(0, 0, 0, 0.18);
	}
}
